import { storyblokEditable } from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";
import { PostStoryblok } from "../storyblok-component-types";

type BlogPostProps = {
  blok: PostStoryblok;
};
const BlogPost = ({ blok }: BlogPostProps) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className=" max-w-[700px]  mx-auto  my-20 px-3">
        <h1 className="text-[30px] font-black font-title mb-6 ">
          {blok.title}
        </h1>
        <div className="text-[17px] leading-7  spacing  tracking-[-0.003em] text-wrapper">
          {render(blok.long_text)}
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
