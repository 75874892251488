import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="61">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#672E9B"
          d="M34.71 61c16.419 0 25.002-15.139 27.95-30.884C65.607 14.371 48.884 4.042 32.93.481 16.98-3.079 0 13.75 0 30.116S18.292 61 34.71 61z"
        ></path>
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M43 36.471v3.312a2.206 2.206 0 01-.717 1.636 2.215 2.215 0 01-1.695.572 21.919 21.919 0 01-9.546-3.39 21.549 21.549 0 01-6.637-6.623 21.82 21.82 0 01-3.396-9.571c-.056-.618.15-1.23.57-1.688A2.215 2.215 0 0123.21 20h3.319a2.211 2.211 0 012.212 1.899c.14 1.06.4 2.1.774 3.102.304.807.11 1.717-.497 2.33l-1.405 1.401a17.681 17.681 0 006.637 6.624l1.404-1.402a2.215 2.215 0 012.334-.497c1.004.374 2.047.633 3.109.773a2.21 2.21 0 011.902 2.241z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
