import "./index.css";
import Layout from "./components/Layout";
import { useStoryblok, StoryblokComponent } from "@storyblok/react";

function App() {
  const slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.replace("/", "");

  const isPreview =
    window.location.toString().includes("_storyblok") ||
    window.location.toString().includes("localhost");
  const version = isPreview ? "draft" : "published";
  const story = useStoryblok(
    slug,
    {
      resolve_relations: ["featured-posts.posts", "selected-posts.posts"],
      version,
    },
    {
      resolveRelations: ["featured-posts.posts", "selected-posts.posts"],
      version,
    }
  );

  if (!story?.content) {
    return (
      <div className="flex text-center items-center  h-full  w-full justify-center min-h-screen">
        Loading...
      </div>
    );
  }

  return (
    <Layout>
      <StoryblokComponent blok={story.content} />
    </Layout>
  );
}

export default App;
